import gql from 'graphql-tag'

export const fetchUpdateCartItemsMutation = gql(`
mutation ($cart_id: String!, $cart_items: [CartItemUpdateInput]!) {
  updateCartItems(
    input: {
      cart_id: $cart_id
      cart_items: $cart_items
    }
  ) {
      cart {
        items {
          id
          product {
            name
            catalog_product_name
            sku
            packaging_enable
            number_of_products_on_layer
            number_of_layers_per_pallet
            material_type
            description {
              html
            },
            url_key
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                },
              },
            },
            swatch_image
            image {
              url
            },
          },
          prices {
            total_item_discount {
              value
            }
            price {
              value
            }
            basic_price
            basic_price_tax
            your_price
            your_price_tax
            total_basic_price
            total_basic_price_tax
            total_your_price
            total_your_price_tax
            favourites
            row_total {value}
            discounts {
              label
              amount {
                value
              }
            }
          }
          quantity
          number_of_packages
          number_of_layer
          number_of_layer_total
          number_of_pallet
          quantity_total
          pack_format
        }
        progress_bar_data {
          isFullTruck
          iMinimumOrderPallet
          iMaximumOrderPallet
          iMinimumOrderWeight
          iMaximumOrderWeight
          iMaximumOrderCash
          sOutletDeliveryMethod
          iTotalCartWeight
          iTotalCartPalletQty
        }
      }
    }
  }
`)
