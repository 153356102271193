
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import HelpActionButton from '@/components/HelpActionButton.vue'
import TutorialModal from '@/components/TutorialModal.vue'
import MessageModal from '@/components/common/MessageModal.vue'
import BannersComponent from '@/components/BannersComponent.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { setI18nLanguage } from '@/i18n'
import Error401Page from './pages/Error401Page.vue'
import { constants } from '@/constants'
import BeforeLoginLayout from '@/components/layout/BeforeLoginLayout.vue'
import { LanguagesType } from '@/types'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import BannerSection from '@/components/homepage/BannerSection.vue'
import AppGlobalModal from '@/components/app/AppGlobalModal.vue'
import ModalComponent from './components/ModalComponent.vue'
import ResizeObserver from '@/components/ResizeObserver.vue'
import { IPermissionsConfig } from './store/modules/main/intefaces/IConfig'
import { RolePermissionDetails } from './store/modules/users/interfaces/IUser'
import { IAuthState } from './store/modules/auth/IAuthState'
import { ISearchValue } from './store/modules/main/intefaces/search/IFetchSearchResponse'
import { CartQueryItem } from './store/modules/basket/interfaces/cartQuery/IBasketQuery'
import { ILinks } from './store/modules/links/interfaces'

export default defineComponent({
  name: 'App',

  components: {
    HelpActionButton,
    TutorialModal,
    Error401Page,
    MessageModal,
    BannersComponent,
    UserToolbar,
    BeforeLoginLayout,
    BannerSection,
    ModalComponent,
    AppGlobalModal,
    ResizeObserver
  },
  inject: ['isPeroni'],
  data: () => ({
    gigICode: '',
    gigIvToken: '',
    isPortalChrome: true,
    theme: constants.application.theme,
    isLoaded: false,
    openModal: false,
    message: '',
    subMessage: '',
    searchValue: '',
    searchValues: [] as ISearchValue[],
    isErrorModal: true,
    clientWidth: 0,
    copyRightMsg: `© ${new Date().getFullYear()} Peroni`,
    activeLanguage: (localStorage.getItem('lang') || 'it') as LanguagesType,
    dictionary: {
      en: {
        createAnOrder: 'CREATE AN ORDER',
        createAnOrderIt: 'Create An Order',
        cart: 'MY CART',
        otherLinks: 'Other links',
        legalClause: 'Legal clause',
        backUp: 'Back up',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Search',
        home: 'Homepage',
        profile: 'My Profile',
        contracts: 'Contracts',
        orders: 'Orders',
        history: 'History',
        savedOrders: 'Saved Orders',
        suggestedCart: 'Suggested Cart',
        catalog: 'Catalogue',
        products: 'Product Overview',
        favorites: 'My Favorites',
        favoritesMenu: 'MY FAVORITES',
        reporting: 'Reporting',
        myDocuments: 'My documents',
        marketingMaterials: 'Marketing Materials',
        newsEvents: 'News and Events',
        aboutPeroniMenu: 'About Peroni',
        support: 'Support',
        faq: 'FAQ',
        knowledgeBase: 'Knowledge Base',
        supportTickets: 'Support Tickets',
        eLearning: 'Academy',
        pubMenu: 'Menu Creator',
        contacts: 'Contacts',
        about: 'About us',
        privacyPolicy: 'Privacy Policy',
        cookies: 'Cookies and personal data protection',
        brands: 'Brands',
        productCode: 'Product code',
        createNewUser: 'Create New User',
        trainingLearning: 'Training & Learning',
        estrattoConto: 'Estratto Conto Vuoti',
        actions: {
          edit: 'My Profile',
          logout: 'Logout'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Login',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Terms of use',
        corporateInformation: 'Corporate Information',
        corporateGovernance: 'Corporate Governance',
        goToCart: 'Go To Cart',
        removeItem: 'Remove Item',
        Unit: 'Unit'
      },
      it: {
        createAnOrder: 'Crea il tuo ordine',
        createAnOrderIt: 'Crea il tuo ordine',
        cart: 'Il Mio Carrello',
        otherLinks: 'Collegamenti Esterni',
        legalClause: 'Legal clause',
        backUp: 'Torna in Alto',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Ricerca',
        home: 'Homepage',
        profile: 'Il mio Profilo',
        contracts: 'Contratti',
        orders: 'I miei ordini',
        history: 'Storico ordini',
        savedOrders: 'Ordini salvati',
        suggestedCart: 'Carrello Suggerito',
        catalog: 'Catalogo',
        products: 'Crea il tuo ordine',
        favorites: 'I miei preferiti',
        favoritesMenu: 'I Miei Preferiti',
        reporting: 'Reportistica',
        myDocuments: 'I miei documenti',
        marketingMaterials: 'Materiali Marketing',
        newsEvents: 'News & Events',
        aboutPeroniMenu: 'Chi siamo',
        support: 'Assistenza',
        faq: 'Domande frequenti',
        knowledgeBase: 'Manuale utente',
        supportTickets: 'Ottieni supporto',
        eLearning: 'Formazione',
        pubMenu: 'Crea il tuo Menù',
        contacts: 'Contacts',
        about: 'About us',
        privacyPolicy: 'Privacy Policy',
        cookies: 'Cookies Policy',
        brands: 'Brands',
        productCode: 'Product code',
        createNewUser: 'Create New User',
        trainingLearning: 'Formazione',
        estrattoConto: 'Estratto Conto Vuoti',
        actions: {
          edit: 'Il Mio Profilo',
          logout: 'Logout'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Login',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Termini & Condizioni',
        corporateInformation: 'Informazioni Societarie',
        corporateGovernance: 'Corporate Governance',
        goToCart: 'Procedi con il Carrello',
        removeItem: 'Elimina Prodotto',
        Unit: 'Unità'
      }
    },
    footerLinksBrands: [
      { title: 'brands' },
      {
        label: 'Pilsner Urquell',
        link: 'https://pilsner-urquell.cz',
        target: '_blank'
      },
      {
        label: 'Gambrinus',
        link: 'https://gambrinus.cz',
        target: '_blank'
      },
      {
        label: 'Velkopopovický Kozel',
        link: 'https://kozel.cz',
        target: '_blank'
      },
      {
        label: 'Radegast',
        link: 'https://radegast.cz',
        target: '_blank'
      },
      {
        label: 'Birell',
        link: 'https://birell.cz',
        target: '_blank'
      },
      {
        label: 'Excellent',
        link: 'https://excelentpivo.cz',
        target: '_blank'
      },
      {
        label: 'Frisco',
        link: 'https://friscodrink.cz',
        target: '_blank'
      },
      {
        label: 'Kingswood',
        link: 'https://kingswood.cz',
        target: '_blank'
      }
    ],
    footerLinksMobile: [
      {
        label: 'Pilsner Urquell',
        link: 'https://pilsner-urquell.cz',
        target: '_blank'
      },
      {
        label: 'Gambrinus',
        link: 'https://gambrinus.cz',
        target: '_blank'
      },
      {
        label: 'Velkopopovický Kozel',
        link: 'https://kozel.cz',
        target: '_blank'
      },
      {
        label: 'Radegast',
        link: 'https://radegast.cz',
        target: '_blank'
      },
      {
        label: 'Birell',
        link: 'https://birell.cz',
        target: '_blank'
      },
      {
        label: 'Excellent',
        link: 'https://excelentpivo.cz',
        target: '_blank'
      },
      {
        label: 'Frisco',
        link: 'https://friscodrink.cz',
        target: '_blank'
      },
      {
        label: 'Kingswood',
        link: 'https://kingswood.cz',
        target: '_blank'
      },
      {
        label: 'Kontakty',
        link: 'https://eobjednavka.prazdroj.cz/kontakty/',
        target: '_blank'
      },
      {
        label: 'Ochrana soukromí',
        link: 'https://eobjednavka.prazdroj.cz/pub/media/pdf/default/Sm_rnice_o_ochran_soukrom__2.pdf',
        target: '_blank'
      },
      {
        label: 'Cookies a ochrana osobních údajů',
        link: 'https://eobjednavka.prazdroj.cz/pub/media/pdf/default/CZ_Pravidla_a_podm_nky_v1_CZ_.pdf',
        target: '_blank'
      }
    ]
  }),

  computed: {
    ...mapState('main', ['permissions']),
    menu () {
      return [
        {
          // label: 'Vytvořit objednávku',
          // href: '/catalog/products',
          name: 'xx',
          isActive: true,
          // icon: 'shopping-bag',
          children: [
            ...this.checkPermission('Home Page', {
              label: 'Hlavní stránka',
              href: '/',
              name: 'home',
              isActive: false,
              icon: 'home-alt'
            }),
            ...this.checkPermission('My Profile', {
              label: 'Můj profil',
              href: '/profile/details',
              name: 'profile',
              isActive: false,
              icon: 'user'
            }),
            ...(!this.isPeroni
              ? this.checkPermission('Catalogue', {
                label: 'Katalog',
                name: 'catalog',
                isActive: false,
                icon: 'beer',
                children: [
                  {
                    label: 'Produkty',
                    href: '/catalog/products',
                    name: 'products',
                    isActive: false
                  },
                  {
                    label: 'Moje oblíbené',
                    href: '/catalog/favorites',
                    name: 'favorites',
                    isActive: false
                  },
                  {
                    label: 'Uložené objednávky',
                    href: '/catalog/saved',
                    name: 'savedOrders',
                    isActive: false
                  }
                ]
              })
              : this.checkPermission('Catalogue', {
                label: 'Katalog',
                name: 'catalog',
                isActive: false,
                icon: 'beer',
                children: [
                  {
                    label: 'Produkty',
                    href: '/catalog/products',
                    name: 'createAnOrderIt',
                    isActive: false
                  },
                  {
                    label: 'Moje oblíbené',
                    href: '/catalog/favorites',
                    name: 'favorites',
                    isActive: false
                  },
                  ...this.checkPermission('Product Recommendation',
                    {
                      label: 'Carrello Suggerito',
                      href: '/catalog/suggested-cart',
                      name: 'suggestedCart',
                      isActive: false
                    }
                  )
                ]
              })),

            ...(!this.isPeroni
              ? this.checkPermission('My Orders', {
                label: 'Objednávky',
                name: 'orders',
                href: '/orders/history',
                isActive: false,
                icon: 'shopping-bag'
              })
              : this.checkPermission('My Orders', {
                label: 'Objednávky',
                name: 'orders',
                isActive: false,
                icon: 'shopping-bag',
                children: [
                  {
                    label: 'Historie objednávek',
                    href: '/orders/history',
                    name: 'history',
                    isActive: false
                  },
                  {
                    label: 'Uložené objednávky',
                    href: '/orders/saved',
                    name: 'savedOrders',
                    isActive: false
                  }
                ]
              })),

            ...(this.isPeroni
              ? this.checkPermission('My Documents', {
                label: 'Moje dokumenty',
                href: '/my-documents',
                name: 'myDocuments',
                isActive: false,
                icon: 'newspaper'
              })
              : []),
            ...this.checkPermission(
              'Empties Management',
              {
                label: 'Estratto Conto Vuoti',
                href: '/estratto-conto',
                name: 'estrattoConto',
                isActive: false,
                icon: 'newspaper'
              },
              this.isOnPremiseEmptiesUser || this.isOnPremiseFranchiseEmptiesUser
            ),
            ...this.checkPermission('Reporting', {
              label: 'Přehled odběrů',
              href: '/reporting',
              name: 'reporting',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Contracts', {
              label: 'Přehled smluv',
              href: '/contracts',
              name: 'contracts',
              isActive: false,
              icon: 'file-contract'
            }),
            ...this.checkPermission('Support', {
              label: 'Podpora',
              href: '/support/faq',
              name: 'support',
              isActive: false,
              icon: 'question-circle',
              children: [
                {
                  label: 'Otázky a odpovědi',
                  href: '/support/faq',
                  name: 'faq',
                  isActive: false
                },
                {
                  label: 'O portálu',
                  href: '/support/knowledge-base',
                  name: 'knowledgeBase',
                  isActive: false
                },
                {
                  label: 'Kontaktujte nás',
                  href: '/support/tickets',
                  name: 'supportTickets',
                  isActive: false
                }
              ]
            }),
            ...this.checkPermission('Marketing Materials', {
              label: 'Materiály pro Vás',
              href: '/marketing-materials',
              name: 'marketingMaterials',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Training & Learning', {
              label: 'Formazione',
              name: 'trainingLearning',
              href: '/training',
              icon: 'heart'
            }, !this.isPeroni),
            ...this.checkPermission('News & Articles', {
              label: 'Novinky a články',
              href: '/news',
              name: 'newsEvents',
              isActive: false,
              icon: 'newspaper'
            }),
            // TODO: add the condition to show this page only for peroni theme. Now commented out ass a quick fix for client testing.
            ...this.checkPermission('About Peroni', {
              label: 'A proposito di Peroni',
              href: this.getLinkByName('aboutPeroniMenu').url,
              name: 'aboutPeroniMenu',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission(
              'Academy',
              {
                label: 'Akademie První u zdroje',
                href: this.getLinkByName('eLearning').url,
                name: 'eLearning',
                isActive: false,
                icon: 'external-link-alt'
              },
              this.isPOSUser || this.isWHSLUser || this.isOutletUser
            ),
            ...this.checkPermission(
              'Pub Menu',
              {
                label: 'Prazdroj menu',
                href: this.getLinkByName('pubMenu').url,
                name: 'pubMenu',
                isActive: false,
                icon: 'external-link-alt'
              },
              this.isPOSUser || this.isOutletUser
            )
          ]
        },
        ...this.checkPermission('Catalogue', {
          label: 'Vytvořit objednávku',
          href: '/catalog/products',
          name: 'createAnOrder',
          isActive: true,
          icon: 'shopping-bag'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Košík',
          name: 'cart',
          ...(this.clientWidth >= 1000
            ? {
                href: '',
                precart: true
              }
            : {
                href: '/cart'
              }),
          icon: 'shopping-cart'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Moje oblíbené',
          name: 'favoritesMenu',
          href: '/catalog/favorites',
          icon: 'heart'
        })
      ]
    },
    portalHardCodeLink () {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    total () {
      return this.$store.state.links.portalHardCodeLink?.total ?? []
    },

    limit () {
      return this.$store.state.links.portalHardCodeLink?.limit ?? []
    },

    footerLinks () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type === 'About Us')
        .map((data) => ({
          label: data.name, // Use the 'label' field from the backend as the label for about us links
          link: data.url,
          target: '_blank',
          order: data.order
        }))
        .sort((a, b) => a.order - b.order)

      return [
        {
          title: 'about'
        },
        ...aboutUsLinks
      ]
    },
    ...mapState('main', ['pageNotFound']),
    ...mapState('basket', ['id', 'items', 'query', 'progressBarMsg']),
    ...mapState('catalog', ['wishlist', 'onlyEmpties']),
    ...mapGetters('basket', ['progressBarMessage']),

    linksStore () {
      return this.$store.state.links
    },

    isEmptyCollections (): boolean {
      return this.items?.length > 0 ? this.items?.find((item: CartQueryItem) => item?.product?.material_type === 'ZQCR') : (localStorage.getItem('onlyEmpties') === '1')
    },

    languages () {
      if (this.user.country === 'SVK') {
        return ['sk', 'en']
      }

      if (this.isPeroni) {
        return ['it', 'en']
      }

      return ['cs', 'en']
    },

    menuLinks () {
      let linksArray:ILinks[] = []
      if (this.linksStore?.links && !Array.isArray(this.linksStore?.links)) {
        linksArray = [this.linksStore?.links]
      } else if (Array.isArray(this.linksStore?.links)) {
        linksArray = this.linksStore?.links
      }
      const links = linksArray.map((link, index) => {
        return {
          label: link?.label,
          href: link?.url,
          name: 'custom-link' + (index + 1),
          isActive: false,
          icon: 'external-link-alt',
          isOther: true,
          children: []
        }
      })

      const menu = [...this.menu]

      const menuWithoutOtherLinks = []
      if (menu[0]?.children?.length > 0) {
        for (let i = 0; i < menu[0]?.children?.length; i++) {
          const element = menu[0].children[i]
          if ('isOther' in element === false) {
            menuWithoutOtherLinks.push(element)
          }
        }
      }
      menu[0].children = (menuWithoutOtherLinks || []).concat(links)

      // Changes as per defect#51877 - Support->Ticket section should be hidden for IT Outlets Customer type
      if (this.isPeroni) {
        const customerType = localStorage.getItem('customertype')
        if (customerType === 'Outlets') {
          for (let i = 0; i < menu[0].children.length; i++) {
            const menuItem = menu[0].children[i]
            if (menuItem.name === 'support') {
              const filterSubMenuList = []
              for (let j = 0; j < menuItem.children.length; j++) {
                const subMenuItem = menuItem.children[j]
                if (subMenuItem.name !== 'supportTickets') {
                  filterSubMenuList.push(subMenuItem)
                }
              }
              menuItem.children = filterSubMenuList
            }
          }
        }
      }

      return menu
    },

    computedDictionary () {
      const links = this.linksStore?.links?.reduce((acc, value, index) => {
        acc['custom-link' + (index + 1)] = value?.title
        return acc
      }, {} as Record<string, string>)

      const activeLanguage = this.activeLanguage as keyof typeof this.dictionary
      return { ...this.dictionary, [activeLanguage]: { ...this.dictionary[activeLanguage], ...links } }
    },

    user () {
      return this.$store.state.auth.user
    },
    isLoggedIn () {
      return this.user.isLoggedIn // && !!this.user.userValidation
    },
    notAuthorized () {
      return this.$route.meta?.requireAuthentication && !this.isLoggedIn
    },
    showFooter (): boolean {
      return !this.$route.meta?.hideFooter
    },
    cartItems () {
      return this.items?.length
    },
    favoritesItems () {
      return this.wishlist?.items_v2.shipto_item_count
    },
    isPOSUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails.some((rp) => rp.customerType === 'POS'))
    },
    isWHSLUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails?.some((rp) => rp.customerType === 'WHSL'))
    },
    isOutletUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === 'Outlets')
      )
    },
    isOnPremiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Empties Management')
      )
    },
    isOnPremiseFranchiseEmptiesUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.rolename === 'On Premise Franchisee Empties Management')
      )
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    helpPagesStore () {
      return this.$store.state.helpPages
    },
    customerType (): RolePermissionDetails | undefined {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return undefined
    },
    userMenuPermission (): IPermissionsConfig {
      return this.permissions || []
    },
    showProfiles (): boolean {
      return this.getValueByLabel(this.userMenuPermission, 'My Profile') && this.allPermission?.includes('My Profile')
    },
    locale (): LanguagesType {
      return this.$i18n.locale as LanguagesType
    }
  },

  watch: {
    isLoggedIn: {
      handler (value) {
        if (value === true) {
          this.fetchLinks()
          this.fetchPortalHardCodeLink()
        }
      },
      immediate: true
    },
    activeLanguage: {
      handler (value, oldValue) {
        if (oldValue ? value !== oldValue : !!value) {
          this.setMagentoStore(value)
          if (this.isLoggedIn) {
            this.fetchLinks()
            this.fetchPortalHardCodeLink()
          }
        }
      },
      immediate: true
    },
    locale: {
      handler (value, oldValue) {
        if (oldValue ? value !== oldValue : !!value) {
          this.activeLanguage = this.locale
        }
      },
      immediate: true
    },
    'user.country': {
      handler () {
        this.setMagentoStore(this.activeLanguage)
      },
      immediate: true
    },
    $route () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
      this.initFromRoute()
    },
    progressBarMsg (value, oldValue) {
      if (oldValue !== value) {
        return value
      }
    }
  },

  created () {
    this.initFromRoute()
  },

  async mounted () {
    this.$nextTick(function () {
      window.gigya.hasSession().then(this.checkSession)
      window.gigya.socialize.addEventHandlers({
        onLogin: async (res: IAuthState) => {
          this.updateUserInfo(res)
          localStorage.setItem('shipToId', '')
          await this.$store.dispatch('basket/CREATE_EMPTY_CART', { root: true })
          await this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
        }
      })
    })
  },

  methods: {
    initFromRoute () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
      this.$store.dispatch('main/FETCH_PERMISSIONS', { root: true })
    },
    getValueByLabel (arrayData: IPermissionsConfig | string, searchString: string) {
      // Parse the JSON array if it hasn't been parsed already
      if (typeof arrayData === 'string') {
        arrayData = JSON.parse(arrayData)
      }
      if (searchString === 'Home Page' || searchString === 'Empties Management' || searchString === 'Product Recommendation') {
        return true
      }

      if (Array.isArray(arrayData) && arrayData.length) {
        let result: string | false = false
        arrayData.forEach((item) => {
          if (item.label === searchString && item.value === '1') {
            result = item.value
            // Early return if a match is found
          }
        })
        return result
      }
      return false
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) localStorage.setItem('customertype', this.customerType?.customerType)
      const isAllowedWithoutLogin = constants.menuWithoutLogin?.includes(value.name)
      const hasPermission = this.allPermission?.includes(permission) && additionalPremmision
      const menuAccess = this.getValueByLabel(this.userMenuPermission, permission)
      return (isAllowedWithoutLogin || hasPermission) && menuAccess ? [value] : []
    },
    hasProductRecommendationPermission () {
      const hasPermission = this.user.permissionSchema?.some(permission => permission.rpDetails.some(
        roleItem =>
          roleItem.permission === 'Product Recommendation'
      ))
      if (hasPermission) {
        sessionStorage.setItem('productrecoenabled', '1')
      } else {
        sessionStorage.setItem('productrecoenabled', '0')
      }

      return hasPermission
    },
    getLinkBasedOnLang (key: string, value: Record<string, any>) {
      const lang = this.activeLanguage
      const links = constants.links.termsOfUse
      const linkURL = this.isPeroni ? links.it : links[lang]
      value.link = linkURL
      return linkURL ? [value] : []
    },
    async dispatchHelpPages () {
      await this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },
    openTutorialModal () {
      if (
        !(this.helpPagesStore !== undefined && this.helpPagesStore.helpPages && this.helpPagesStore.helpPages.length)
      ) {
        this.dispatchHelpPages()
      }
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).open()
    },
    closeTutorialModal () {
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).close()
    },
    menuNavigate (item: CustomEvent) {
      if (
        item.detail?.name === 'eLearning' ||
        item.detail?.name === 'pubMenu'
      ) {
        this.$gtm.trackEvent({
          event: item.detail?.name + '_click'
        })

        window.gigya.fidm.saml.initSSO({
          spName: item.detail?.name,
          redirectURL: item.detail.href
        })
        return
      } else if (/^https?:\/\//i.test(item.detail.href) || /^https?:\/\//i.test(item.detail)) {
        const link = item.detail.href ? item.detail.href : item.detail
        window.open(link, '_blank')
        return
      }

      this.$navigate(item)
    },

    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },

    fetchLinks () {
      this.$store.dispatch(LinksActionType.FETCH_ALL_LINKS)
    },
    fetchPortalHardCodeLink () {
      this.$store.dispatch(LinksActionType.FETCH_PORTAL_HARD_CODE_LINK)
    },
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },

    /**
     * Method to update logged in user info into vuex store
     * @param res
     */
    async updateUserInfo (res: IAuthState) {
      sessionStorage.setItem('uid', res.UID) // This is for Google Analytics
      this.$store.commit('SET_AUTH_USER', res)
      await this.$store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID).then(() => {
        if (!this.isPeroni && localStorage.getItem('lang') !== null) {
          this.activeLanguage = (localStorage.getItem('lang') ||
            (constants.application.theme === 'prazdroj' ? 'cs' : 'en')) as LanguagesType
        }
        if (!this.user.userValidation && !this.$route.meta?.isUserVerification) {
          this.userLogout()
        }
      })
      if (!this.isPeroni && !!this.user.userValidation && !this.$route.meta?.isUserVerification) {
        await this.checkPOSAcademyUser()
      }
      if (this.user.firstLogin === true) {
        this.openTutorialModal()
      }
      this.hasProductRecommendationPermission()
    },
    checkPOSAcademyUser () {
      const permission = this.user.permissionSchema
      if (permission) {
        permission.forEach((item) => {
          const rolePOSAcademy = item.rpDetails.find(
            (roleItem) =>
              roleItem.rolename === 'POS Academy' || roleItem.roleid === 'f5ab4859-9197-47e5-bc9e-097283c35d94'
          )
          if (rolePOSAcademy) {
            window.gigya.fidm.saml.initSSO({
              spName: 'eLearning',
              redirectURL: this.getLinkByName('eLearning').url
            })
          }
        })
      }
    },
    /**
     * Method to initiate logout action
     * on click action of logout button
     */
    userLogout () {
      localStorage.clear()
      sessionStorage.removeItem('uid')
      sessionStorage.removeItem('imp_usr')
      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    /**
     * Method to perform action after user logs out
     * Navigate user to home page
     */
    handleLogout () {
      this.$navigate('/login')
      this.$store.dispatch('logout')
    },
    /**
     * Method to check user session if it exist
     * if it does not exist then redirect user to login page
     * @param sessionExist
     */
    async checkSession (sessionExist: boolean) {
      if (sessionExist) {
        window.gigya.socialize.getUserInfo({ callback: this.updateUserInfo })
      } else {
        if (!this.$route.meta?.isUserVerification && this.$route.meta?.requireAuthentication && !this.isPeroni) {
          this.$navigate('/login')
        }
      }
      this.isLoaded = true
    },
    /**
     * Callback method on click of Profile link under top right user name
     * @param event
     */
    userEdit () {
      this.$navigate('/profile/details')
    },

    setAgeConfirmed (isAgeConfirmed: boolean) {
      if (this.theme === 'peroni') {
        this.isPortalChrome = isAgeConfirmed
      }
    },
    setMagentoStore (language: string) {
      localStorage.setItem('store', `LivePortal_IT_${language}`)
    },

    async search (event: CustomEvent) {
      this.searchValue = event.detail
      await this.fetchSearching(this.searchValue)
    },

    async fetchSearching (dto: string): Promise<ISearchValue[] | undefined> {
      try {
        if (dto != null && dto !== '') {
          const response: AxiosResponse = await instance.get<ISearchValue[]>(`/downloadmaterial/GetAllDMAndArticles?Search=${dto}`)
          this.searchValues = response.data.results.map((items: ISearchValue) => ({ ...items, name: items?.file?.name || items?.name, path: items?.file?.link || items?.path }))
        } else {
          this.searchValues = new Array(0) as ISearchValue[]
        }
        return this.searchValues
      } catch (error) {
        console.error(error)
      }
    },
    async removeProductFromCart (productId: string) {
      if (this.$store.state.basket?.id && productId) {
        await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.$store.state.basket.id, cart_item_id: productId }, { root: true })
        await this.$store.dispatch('basket/FETCH_MIN_CART_QUERY', { cart_id: this.$store.state.basket.id }, { root: true })
      }
    },

    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    }
  }
})
